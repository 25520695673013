import { Model } from '@vuex-orm/core'

export class CloudServerTemplate extends Model {
    static entity = 'cloud_server_template'
    static primaryKey = 'key'

    static fields () {
        return {
            key: this.string(''),
            title: this.string('')
        }
    }

    static async fetchAll() {
        /*
         * TODO: Uncomment if implemented in v2 api
         * const templates = await api.getCloudServerTemplates()
         */
        await CloudServerTemplate.insertOrUpdate({
            data: [
                {
                    key: 'DEBIAN_10',
                    title: 'Debian 10'
                }
            ]
        })
        return CloudServerTemplate.all()
    }
}
