import { Model } from '@vuex-orm/core'
import api from '@/core/services/openapi.service'

export default class CloudServerPricing extends Model {
    static entity = 'cloud_server_pricing'
    static primaryKey = 'id'

    static fields () {
        return {
            customer: this.attr(null),
            free_units: this.number(0),
            id: this.number(0),
            max_units: this.number(0),
            min_units: this.number(0),
            price: this.number(0),
            range: this.number(0),
            step: this.number(0),
            unit: this.string('')
        }
    }

    static async fetch(customer_id, project_uuid) {
        const res = await api.getCloudServerPricing({ customer_id, project_uuid })

        await CloudServerPricing.insertOrUpdate({
            data: Object.values(res.data)
        })
    }

    static async getByUnit(unit) {
        let pricing = CloudServerPricing.query().where('unit', unit).first()
        if (unit === 'memory') {
            pricing = {
                ...pricing,
                min_units: pricing.min_units / 1024,
                max_units: pricing.max_units / 1024,
                step: pricing.step / 1024
            }
        }
        return pricing
    }

    static allAsObject () {
        return CloudServerPricing.all().reduce((acc, val) => ({ ...acc, [val.unit]: val }), {})
    }
}
