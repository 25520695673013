<template>
    <router-view />
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Main demo style scss
@import "assets/sass/style.vue";
</style>

<script>
export default {
    name: 'LumaservCloud',
    mounted () {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
        this.$store.dispatch('config/overrideLayout')
    }
}
</script>
