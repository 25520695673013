import { Model } from '@vuex-orm/core'
import api from '@/core/services/openapi.service'

export class FirewallRuleSet extends Model {
    static entity = 'firewall_rule_sets';

    static route = 'firewall-rule-sets-single'

    static primaryKey = 'id'

    static fields () {
        return {
            id: this.number(0),
            title: this.string(''),
            project_uuid: this.string(''),
            user: this.string('')
        }
    }

    static async fetchAll () {
        const response = await api.getFirewallRuleSets()

        await this.insert({
            data: response.data
        })
    }

    static filter(filter, project_uuid = null) {
        let query = this.query().where(p => (p.title || '').includes(filter))
        if (project_uuid) { query = query.where('project_uuid', project_uuid) }
        return query
    }

    static async fetchSingle (id) {
        const response = await api.getFirewallRuleSet(id)
        await this.insertOrUpdate(response)
        return this.find(id)
    }
}
