import { Model } from '@vuex-orm/core'
import api from '@/core/services/openapi.service'

export class FirewallRule extends Model {
    static entity = 'firewall_rules'
    static primaryKey = 'id'

    static fields () {
        return {
            id: this.number(0),
            title: this.string(''),
            order: this.number(0),
            direction: this.string(''),
            action: this.string(''),
            interface: this.string(''),
            protocol: this.string(''),
            source_address: this.string(''),
            source_address_set_id: this.number(0),
            destination_address: this.string(''),
            destination_address_set_id: this.number(0),
            source_port: this.string(''),
            destination_port: this.string(''),
            created_at: this.string(''),
            updated_at: this.string('')

        }
    }

    static fetchById = async id => {
        const res = await api.getFirewallRuleSetRules(id)
        await FirewallRule.deleteAll()
        await FirewallRule.insertOrUpdate({ data: res.data })
        return res.data
    }

    static updateById = async (id, rule_id, body) => {
        const res = await api.updateFirewallRuleSetRule(id, rule_id, body)
        await FirewallRule.update({ data: res.data })
        return res.data
    }

    static createById = async (id, body) => {
        const res = await api.createFirewallRuleSetRule(id, body)
        await FirewallRule.insertOrUpdate({ data: res.update })
        return res.data
    }

    static deleteById = async (id, rule_id) => {
        const res = await api.deleteFirewallRuleSetRule(id, rule_id)
        await FirewallRule.delete(rule_id)
        return res.data
    }
}
